<template>
  <div class="mine">
    <footer>
      <van-tabbar v-model="active" active-color="#3CDEA6">
        <van-tabbar-item to="/treatmentServices">
          <template #icon="props">
            <i
              :class="props.active ? 'zhenliaofuwu-active' : 'zhenliaofuwu'"
            ></i>
          </template>
          <span class="text">诊疗服务</span>
        </van-tabbar-item>
        <van-tabbar-item to="/medicalGuide">
          <template #icon="props">
            <i :class="props.active ? 'jiuyizhinan-active' : 'jiuyizhinan'"></i>
          </template>
          <span class="text">就医指南</span>
        </van-tabbar-item>
        <van-tabbar-item to="/personalCenter">
          <template #icon="props">
            <i
              :class="props.active ? 'gerenzhongxin-active' : 'gerenzhongxin'"
            ></i>
          </template>
          <span class="text">个人中心</span>
        </van-tabbar-item>
      </van-tabbar>
    </footer>
    <!-- 个人中心头部-->
    <div class="headr">
      <div class="headr1">
        <img :src="headimgurl" alt="" />
        <div style="padding-left: 10px" class="text">{{ nickname }}</div>
      </div>
    </div>
    <!-- 个人中心中间 -->
    <div class="grid-item">
      <van-grid :border="false" :column-num="3">
        <van-grid-item to="/healthCard">
          <template #text>
            <span class="text">就诊人管理</span>
          </template>
          <template #icon>
            <img src="../../assets/img/ucenter/3.png" alt="" />
          </template>
        </van-grid-item>
        <van-grid-item>
          <template #text>
            <span class="text">在线充值</span>
          </template>
          <template #icon>
            <i class="zaixianchongzhi-disabled"></i>
          </template>
        </van-grid-item>
        <van-grid-item @click="routerPush('/electronics')">
          <template #text>
            <span class="text">电子发票</span>
          </template>
          <template #icon>
            <img src="../../assets/img/ucenter/9.png" alt="" />
          </template>
        </van-grid-item>
        <van-grid-item>
          <!-- @click="routerPush('/register')" -->
          <template #text>
            <span class="text">预约挂号记录</span>
          </template>
          <template #icon>
            <img src="../../assets/img/home/yuyueguahao-disabled.png" alt="" />
          </template>
        </van-grid-item>
        <van-grid-item>
          <template #text>
            <span class="text">住院充值记录</span>
          </template>
          <template #icon>
            <img
              src="../../assets/img/ucenter/zhuyuanchongzhi-disabled.png"
              alt=""
            />
          </template>
        </van-grid-item>
        <van-grid-item>
          <template #text>
            <span class="text">电子陪护证</span>
          </template>
          <template #icon>
            <img src="../../assets/img/ucenter/10.png" alt="" />
          </template>
        </van-grid-item>
      </van-grid>
    </div>
    <!-- 个人中心底部 -->
    <div class="dibu">
      <div class="dibu1" @click="goto">
        <div class="dibu2">
          <img src="../../assets/img/ucenter/11.png" alt="" />
          <span class="dibu3" style="font-size: 0.4rem">我的医生</span>
        </div>
        <div class="dibu4">
          <img src="../../assets/img/ucenter/12.png" alt="" />
        </div>
      </div>
      <div class="line"></div>
      <div class="dibu1" @click="help">
        <div class="dibu2">
          <img src="../../assets/img/ucenter/13.png" alt="" />
          <span class="dibu3" style="font-size: 0.4rem">帮助反馈</span>
        </div>
        <div class="dibu4">
          <img src="../../assets/img/ucenter/12.png" alt="" />
        </div>
      </div>
      <div class="line"></div>
      <div class="dibu1" @click="Investigation">
        <div class="dibu2">
          <img src="../../assets/img/ucenter/14.png" alt="" />
          <span class="dibu3" style="font-size: 0.4rem">问卷调查</span>
        </div>
        <div class="dibu4">
          <img src="../../assets/img/ucenter/12.png" alt="" />
        </div>
      </div>
      <div class="line"></div>
      <!-- <div class="dibu1" @click="traditionalMedicalRecord">
        <div class="dibu2">
          <img src="../../assets/img/ucenter/16.png" alt="" />
          <span class="dibu3" style="font-size: 0.4rem">我的订单</span>
        </div>
        <div class="dibu4">
          <img src="../../assets/img/ucenter/12.png" alt="" />
        </div>
      </div> -->
    </div>
  </div>
</template>

<script>
export default {
  name: "personalCenter",
  data() {
    return {
      active: 2,
      headimgurl: "",
      nickname: "",
    };
  },
  methods: {
    routerPush(path) {
      this.$router.push({
        path: path,
        query: {
          activeName: "a",
        },
      });
    },
    record() {
      // 报告人查询
      this.$router.push({
        name: "record",
      });
    },

    goto() {
      // 我的医生
      this.$router.push({
        name: "doctor",
      });
    },

    help() {
      //帮助反馈
      this.$router.push({
        name: "help",
      });
    },
    Investigation() {
      // 问卷调查
      this.$router.push({
        name: "investigation",
      });
    },
    traditionalMedicalRecord() {
      this.$router.push({
        name: "traditionalMedicalRecord",
      });
    },
  },
  created() {
    let user = JSON.parse(sessionStorage.getItem("user"));
    if (user) {
      this.headimgurl = user.headimgurl;
      this.nickname = user.nickname;
    }
  },
};
</script>
<style scoped>
.body {
  background-color: #ffededed;
}
.mine {
  width: 100%;
  height: 100%;
}
.headr {
  width: 100%;
  height: 3.8rem;
  background: url("../../assets/img/ucenter/1.png");
  background-repeat: no-repeat;
  background-size: 100% 100%;
  position: relative;
}
.headr1 {
  width: 5rem;
  height: 3.8rem;
  line-height: 1.9rem;
  display: flex;
  margin-left: 20px;
  position: absolute;
  top: 25%;
  font-size: 14px;
  color: #ffffffff;
}
.headr img {
  width: 2rem;
  height: 2rem;
  border-radius: 50%;
}
.zhongjian1 {
  width: 2.25rem;
  height: 60px;
  font-size: 12px;
  color: #8d8d8d;
}
.zhongjian1 img {
  width: 0.8rem;
  height: 0.8rem;
}
.dibu {
  width: 9rem;
  margin: auto;
  border-radius: 10px;
  margin-bottom: 50px;
  text-align: left;
  background-color: #ffffffff;
  margin-top: 0rem;
  -webkit-box-shadow: #e6e6e6 0px 0px 10px;
  -moz-box-shadow: #e6e6e6 0px 0px 10px;
  box-shadow: #e6e6e6 0px 0px 10px;
}
.dibu1 {
  width: 9rem;
  height: 1.15rem;
  font-size: 14px;
  line-height: 1.15rem;
  display: flex;
  justify-content: space-between;
  align-content: center;
}
.line {
  width: 8.6rem;
  height: 1px;
  margin: 0 auto;
  background-color: #eeeeee;
}
.dibu1:last-child {
  border-bottom: 0;
}

.dibu1 img {
  width: 0.6rem;
  height: 0.6rem;
  vertical-align: middle;
  margin: 0 15px;
}
.dibu4 img {
  width: 0.2rem;
  height: 0.3rem;
  vertical-align: middle;
  margin: 0 10px;
}
/deep/.van-tabbar {
  height: 1.3rem;
}
.text {
  font-size: 0.3rem;
}
/deep/.van-grid-item img {
  height: 0.8rem;
  width: 0.8rem;
}
/deep/.van-grid-item__content {
  height: auto;
}
.grid-item {
  width: 9rem;
  background-color: #fff;
  border-radius: 10px;
  margin: 0 auto;
  -webkit-box-shadow: #e6e6e6 0px 0px 10px;
  -moz-box-shadow: #e6e6e6 0px 0px 10px;
  box-shadow: #e6e6e6 0px 0px 10px;
  overflow: hidden;
  position: relative;
  top: -0.5rem;
}
.dortor-disabled {
  height: 0.65rem !important;
  width: 0.6rem !important;
  position: relative;
  left: -2px;
}
</style>
